import React from 'react'
import "./App.css"
import FORM from './component/FORM'

const App = () => {
  return (
    <div>
      <FORM/>
    </div>
  )
}

export default App
